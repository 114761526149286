.container {
  display: flex;
  column-gap: 16px;
}

@media screen and (min-width: 1920px) {
  .container {
    column-gap: 20px;
  }

  .button {
    border-radius: 48px;
    span {
      font-size: 20px;
      line-height: 32px;
      padding: 21px 36.4px;
    }
  }
}

@media screen and (min-width: 2560px) {
  .container {
    column-gap: 24px;
  }

  .button {
    border-radius: 48px;
    span {
      font-size: 24px;
      font-weight: 800;
      line-height: 36px;
      padding: 24px 42px;
    }
  }
}
