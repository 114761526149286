.container {
  font-weight: 900;
  text-transform: uppercase;
  color: var(--bs-white);
  margin-bottom: 0;
  font-size: 16px;
  font-family: integralcf-bold;
  padding: 20px 24px;
  line-height: 20px;
  cursor: pointer;
}

@media screen and (min-width: 674px) {
  .container {
    font-size: 36px;
    padding: 30px 36px;
    line-height: 24px;
  }
}
