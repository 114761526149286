.container {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #8c5effb3;
  min-height: 100dvh;
  transition: background-color 0.2s;
  width: 100%;
  overflow: hidden;

  &.user {
    background-color: #8c5effe6;
  }

  &.business {
    background-color: #00b6f4cc;
  }

  &::before {
    content: "";
    position: absolute;
    inset: 0;
    z-index: -1;
    background: linear-gradient(#00b6f4, #4b308f 80%);
  }

  &::-webkit-scrollbar {
    display: none;
  }
}

.banner {
  display: flex;
  justify-content: space-between;
  padding: 80px 16px;
  width: 100vw;
  max-width: 1200px;
  column-gap: 48px;
  min-height: 531px;
}

.body {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: calc(100dvh - 60px);
  margin-top: 40px;
  width: 100%;

  &::-webkit-scrollbar {
    display: none;
  }
}

.popup {
  height: 100%;
  width: 100%;
  img {
    height: 100%;
    width: 100%;
  }
}

.dialogContent {
  padding: 0px !important;
  max-height: 932px;
}

@media screen and (min-width: 674px) {
  .body {
    min-height: calc(100dvh - 82px);
    row-gap: 56px;
  }
}

@media screen and (min-width: 1920px) {
  .body {
    row-gap: 124px;
  }
}

:global {
  #root {
    overflow-y: auto;
    overflow-x: hidden;
    height: 100dvh;
    width: 100dvw;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}
