.container {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 80px 32px;
  padding-top: 0px;
  width: 100vw;
  max-width: 1200px;
  column-gap: 8px;
  height: calc(100dvh - 250px);
  min-height: 663px;
  opacity: 1;

  // animation: slideDown 800ms ease-in-out forwards 200ms;
}

.header {
  font-family: integralcf-bold;
  font-size: 32px;
  font-weight: 800;
  line-height: 37px;
  color: var(--bs-white);
  white-space: pre-wrap;
  text-align: center;
  min-height: 111px;
}

.subHeader {
  font-size: 14px;
  line-height: 19px;
  color: var(--bs-white);

  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: normal;
  text-align: center;
  min-height: 73px;
  // width: 515px;
}

.label {
  font-family: integralcf-bold;
  font-size: 16px;
  font-weight: 800;
  line-height: 24px;
  padding: 16px 0px;
  color: #fff;
  text-transform: uppercase;
}

.banner {
  transform: scale(0.5);
  transform-origin: center;
}

@keyframes slideDown {
  0% {
    opacity: 0;
    transform: translateY(-5px);
  }

  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@media screen and (min-width: 1024px) {
  .banner {
    transform: scale(0.8);
    transform-origin: top;
  }

  .container {
    flex-direction: row;
    column-gap: 0px;
    min-height: 531px;
    padding-bottom: 0px;
    height: calc(100dvh - 450px);
  }
}

@media screen and (min-width: 1440px) {
  .banner {
    transform: scale(1);
    transform-origin: center;
  }
  .container {
    flex-direction: row;
    column-gap: 40px;
    min-height: 531px;
    padding: 0px;
    height: calc(100dvh - 450px);
  }
}

@media screen and (min-width: 1920px) {
  .banner {
    transform: scale(1.3);
  }
  .container {
    max-width: 1800px;
    left: -60px;
  }
}

@media screen and (min-width: 2560px) {
  .banner {
    transform: scale(1.5);
  }
  .container {
    max-width: 1800px;
    left: -120px;
  }
}
