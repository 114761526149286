.container {
  position: absolute;
  inset: 0;
  left: 394px;
}

.rate18 {
  position: absolute;
  left: 1.2%;
  top: 13%;
  min-width: 184px;
}

.food {
  position: absolute;
  left: 13.5%;
  top: 41%;
  min-width: 184px;
}

.couple {
  position: absolute;
  left: 26.4%;
  top: -3.5%;
  min-width: 184px;
}

.comedy {
  position: absolute;
  left: 41%;
  top: 39%;
  min-width: 184px;
}

.sports {
  position: absolute;
  right: 32.2%;
  top: 16%;
  min-width: 184px;
}

.family {
  position: absolute;
  right: 7.2%;
  top: 12.8%;
  min-width: 184px;
}

.tourism {
  position: absolute;
  right: 8.5%;
  top: 47%;
  min-width: 184px;
}

.comedy,
.sports,
.family,
.tourism {
  display: none;
}

@media screen and (min-width: 674px) {
  .container {
    left: 0;
  }
  .rate18 {
    left: 13.3%;
    top: 6%;
  }

  .couple {
    left: 35%;
    top: 9%;
  }

  .comedy,
  .sports,
  .family,
  .tourism {
    display: flex;
  }
}

@media screen and (min-width: 1440px) {
  .rate18 {
    left: 15%;
    top: 8%;
  }

  .food {
    left: 12.7%;
    top: 46.1%;
  }

  .couple {
    left: 36.5%;
    top: 12%;
  }

  .comedy {
    left: 42.3%;
    top: 42%;
  }

  .sports {
    right: 33.6%;
    top: 19.5%;
  }

  .family {
    right: 8.9%;
    top: 16.1%;
  }

  .tourism {
    right: 10.2%;
    top: 49%;
  }
}

@media screen and (min-width: 2560px) {
  .rate18 {
    left: 15.6%;
    top: 12%;
    min-width: 296px;
  }

  .food {
    left: 13.3%;
    top: 50.1%;
    min-width: 296px;
  }

  .couple {
    left: 37.1%;
    top: 15.2%;
    min-width: 296px;
  }

  .comedy {
    left: 43%;
    top: 45%;
    min-width: 296px;
  }

  .sports {
    right: 34.2%;
    top: 23%;
    min-width: 296px;
  }

  .family {
    right: 9.4%;
    top: 19.1%;
    min-width: 296px;
  }

  .tourism {
    right: 10.8%;
    top: 52.8%;
    min-width: 296px;
  }
}
