.container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &.show {
    .body {
      transform-origin: top;
      animation: slideIn 800ms ease-in-out forwards;
    }

    .arrow {
      animation: slideDown 600ms ease-out forwards;
    }

    .wave {
      animation: ripple 2s ease-in-out forwards 300ms;

      &::after {
        animation: ripple-2 2s ease-in-out forwards 700ms;
      }
    }
  }

  &.hide {
    transform-origin: bottom;
    animation: shrinkDown 800ms ease-in-out forwards;
  }
}

.body {
  display: flex;
  position: relative;
  z-index: 1;
  padding: 12px;
  justify-content: space-between;
  background: #ffffff;
  border-radius: 12px;
  width: fit-content;
  opacity: 0;
}

.col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  column-gap: 2px;
}

.mid {
  transition: all 600ms ease-in-out, opacity 400ms ease-in-out 300ms;
  overflow: hidden;
  max-height: 40px;
  min-height: 40px;
  width: 0px;
  padding-left: 0px;
  opacity: 0;
  &.show {
    width: 120px;
    padding-left: 12px;
    opacity: 1;
  }
}

.icon {
  height: 40px;
  width: 40px;
  img {
    height: 100%;
    width: 100%;
  }
}

.title {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 700;
  line-height: 16.8px;
  text-align: left;
  color: #111111;
  white-space: nowrap;
}

.label {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.4px;
  text-align: left;
  color: #111111;
  white-space: nowrap;
}

.count {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 700;
  line-height: 16.8px;
  text-align: left;
  color: #292933;
  white-space: nowrap;
}

.percent {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 700;
  line-height: 21.6px;
  text-align: left;
  white-space: nowrap;
}

.arrow {
  position: absolute;
  z-index: 0;
  bottom: 16px;
  left: calc(50% - 12px);
  transform: translateX(-50%);
  opacity: 0;

  &::after {
    content: "";
    position: absolute;
    width: 24px;
    height: 24px;
    background: #fff;
    border-radius: 4px;
    transform: rotate(-45deg);
  }
}

.waveContainer {
  position: absolute;
  z-index: 0;
  bottom: -24px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
}

.wave {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  opacity: 0;
  width: 2px;
  height: 1px;
  border: #fff 4px solid;
  border-radius: 300px / 150px;
}

.wave:after {
  content: "";
  position: absolute;
  opacity: 0;
  width: 2px;
  height: 1px;
  border: #fff 2px solid;
  border-radius: 75px / 37.5px;
}

@keyframes ripple {
  from {
    opacity: 1;
  }
  to {
    width: 150px;
    height: 75px;
    border-width: 1px;
    opacity: 0;
  }
}

@keyframes ripple-2 {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    width: 50px;
    height: 25px;
    border-width: 1px;
  }
}

@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  50% {
    opacity: 1;
    transform: scale(1.2) translateY(0px);
  }
  100% {
    opacity: 1;
    transform: scale(1) translateY(0px);
  }
}

@keyframes slideDown {
  0% {
    opacity: 0;
    transform: translateY(-24px);
  }
  50% {
    opacity: 0;
    transform: translateY(-12px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes shrinkDown {
  0% {
    transform: rotate(0deg) scale(1);
  }
  40% {
    transform: rotate(2deg) scale(1.1);
  }
  100% {
    transform: rotate(-12deg) scale(0);
  }
}

@media screen and (min-width: 2560px) {
  .body {
    padding: 18px;
    border-radius: 18px;
  }
  .col {
    column-gap: 3px;
  }

  .mid {
    max-height: 60px;
    min-height: 60px;
    width: 0px;
    &.show {
      width: 180px;
      padding-left: 18px;
      opacity: 1;
    }
  }

  .icon {
    height: 60px;
    width: 60px;
  }

  .title {
    font-size: 21px;
    line-height: 25.2px;
  }

  .label {
    font-size: 18px;
    line-height: 21.6px;
  }

  .count {
    font-size: 21px;
    line-height: 25.2px;
  }

  .percent {
    font-size: 27px;
    line-height: 32.4px;
  }
}
