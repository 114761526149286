.container {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  position: relative;
  z-index: 1;
}

.header {
  font-family: integralcf-bold;
  font-size: 32px;
  font-weight: 800;
  line-height: 37px;
  color: var(--bs-white);
  white-space: pre-wrap;
}

.subHeader {
  font-size: 14px;
  line-height: 19px;
  color: var(--bs-white);

  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.label {
  font-family: integralcf-bold;
  font-size: 16px;
  font-weight: 800;
  line-height: 24px;
  padding: 16px 0px;
  color: #fff;
  text-transform: uppercase;
}

@media screen and (min-width: 1024px) {
  .container {
    row-gap: 24px;
    max-width: 430px;
    min-width: 430px;
  }
  .header {
    font-size: 48px;
    line-height: 55.5px;
  }

  .subHeader {
    font-size: 14px;
    line-height: 19px;
    width: 400px;
  }

  .label {
    font-size: 16px;
    line-height: 24px;
  }
}

@media screen and (min-width: 1440px) {
  .container {
    row-gap: 24px;
    max-width: 564px;
    min-width: 564px;
  }
  .header {
    font-size: 64px;
    font-weight: 800;
    line-height: 70.4px;
  }

  .subHeader {
    font-size: 18px;
    line-height: 27px;
    font-weight: 300;
    width: 515px;
  }

  .label {
    font-size: 16px;
    font-weight: 800;
    line-height: 24px;
  }
}

@media screen and (min-width: 1920px) {
  .container {
    row-gap: 32px;
    max-width: 773px;
    min-width: 773px;
  }
  .header {
    font-size: 83.2px;
    line-height: 91.5px;
  }

  .subHeader {
    font-size: 24.3px;
    line-height: 35.1px;
    width: 669px;
  }

  .label {
    font-size: 20.8px;
    line-height: 31.2px;
  }
}

@media screen and (min-width: 2560px) {
  .container {
    row-gap: 36px;
    max-width: 846px;
    min-width: 846px;
  }
  .header {
    font-size: 96px;
    line-height: 105.6px;
  }

  .subHeader {
    font-size: 27px;
    line-height: 40.5px;
    width: 773px;
  }

  .label {
    font-size: 24px;
    line-height: 36px;
  }
}
