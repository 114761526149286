.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: radial-gradient(
    74.26% 37.09% at 50% 50%,
    #8c5eff 0%,
    #733bff 100%
  );
}

.image {
  img {
    height: 100%;
    width: 100%;
  }
}

.details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 12px;
  padding-bottom: 32px;
  padding-left: 32px;
  padding-right: 32px;
}

.header {
  font-family: integralcf-bold;
  font-size: 33px;
  font-weight: 900;
  line-height: 26px;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  margin-bottom: 12px;
}

.subHeader {
  font-family: ChronicaPro-Medium;
  font-size: 13px;
  font-weight: 500;
  line-height: 10.4px;
  text-align: center;
  color: #fff;
}

.link {
  color: #fff;
  font-size: 14px;
  font-family: ChronicaPro-Medium;
  cursor: pointer;
}

.actionBtns {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  margin-top: 8px;
}

.button {
  padding: 16px 28px;

  img {
    height: 40px;
    width: 40px;
  }

  span {
    padding: 0px;
    width: 100%;
  }
}

@media screen and (min-width: 674px) {
  .image {
    height: 500px;
  }

  .button {
    width: 230px;
  }
  .actionBtns {
    flex-direction: row;
    column-gap: 16px;
  }

  .header {
    font-size: 55px;
    line-height: 44px;
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 1200px) {
  .image {
    height: 558px;
  }
}
