.container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  max-width: 420px;
  // column-gap: 48px;
  min-height: 663px;
  // height: calc(100dvh - 250px);
  position: relative;
  z-index: 2;
  opacity: 0;
  animation: slideIn 800ms ease-in-out forwards;
}

.form {
  display: flex;
  flex-direction: column;
  border-radius: var(--bs-border-radius-xxl);
  background-color: #f3f1f1;
  padding: 1rem 1rem;
  color: var(--bs-card-color);
  width: calc(100vw - 32px);
  max-width: 420px;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.2);
  min-height: 704px;
}

.header {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  margin-bottom: 20px;

  .title {
    font-weight: 900;
    text-align: center;
    font-size: 55px;
    color: var(--bs-primary);
    line-height: 44px;
    margin-top: 20px;
    font-family: integralcf-extrabold;
  }

  .subHeader {
    color: var(--bs-primary);
    text-align: center;
    margin-bottom: 0;
    font-family: ChronicaPro-Regular;
  }
}

.inputs {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}

.actionBtns {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 16px;
  margin-bottom: 16px;
  text-align: center;

  .link {
    color: #733bff;
    font-size: 14px;
    font-family: ChronicaPro-Medium;
  }
}

@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }
  50% {
    opacity: 1;
    transform: translateY(-5px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@media screen and (min-width: 674px) {
  .container {
    min-height: clamp(531px, calc(100vh - 450px), 703px);
  }
}

@media screen and (min-width: 1920px) {
  .container {
    position: relative;
    top: 100px;
  }
  .form {
    transform: scale(1.5);
  }
}
