.container {
  display: flex;
  width: 560px;
  min-width: 560px;
  height: 341px;
  position: relative;
  overflow: visible;
  opacity: 0;
  transition: 200ms opacity ease-in-out;

  &.show {
    opacity: 1;
  }
}

.phone1 {
  z-index: 1;
  position: absolute;
  width: 434px;
  height: 670px;
  right: 58px;
  top: -145px;
  animation: phone1 16s ease-in-out infinite;

  img {
    height: 100%;
    width: 100%;
    position: relative;
    animation: float 8s ease-in-out infinite reverse;
  }
}

.phone2 {
  z-index: 2;
  position: absolute;
  width: 434px;
  height: 670px;
  left: 58px;
  top: -145px;
  animation: phone2 16s ease-in-out infinite;

  img {
    height: 100%;
    width: 100%;
    position: relative;
    animation: float 12s ease-in-out infinite reverse;
  }
}

.phone3 {
  z-index: 2;
  position: absolute;
  width: 238px;
  height: 467px;
  left: 158px;
  animation: phone3 16s ease-in-out infinite;

  img {
    height: 100%;
    width: 100%;
    position: relative;
    animation: float 12s ease-in-out infinite reverse;
  }
}

.tablet {
  z-index: 1;
  width: 506px;
  height: 399px;
  position: absolute;
  transform: scale(0);
  opacity: 0;
  animation: tablet 16s ease-in-out infinite;

  img {
    height: 100%;
    width: 100%;
    position: relative;
    animation: float 8s ease-in-out infinite reverse;
  }
}

.particle1 {
  position: absolute;
  z-index: 0;
  width: 102px;
  height: 102px;
  left: 0px;
  top: 0px;
  left: 200px;
  animation: particle1 16s ease-in-out infinite;
  img {
    height: 100%;
    width: 100%;
    position: relative;
    animation: spin 16s ease-in-out infinite reverse;
  }
}

.particle2 {
  position: absolute;
  z-index: 0;
  width: 122px;
  height: 122px;
  left: 200px;
  bottom: 0px;
  transform: scale(0);
  animation: particle2 16s ease-in-out infinite;
  img {
    height: 100%;
    width: 100%;
    position: relative;
    animation: spin 16s ease-in-out infinite reverse;
  }
}

.particle3 {
  position: absolute;
  z-index: 0;
  width: 66px;
  height: 66px;
  right: 200px;
  top: 0px;
  animation: particle3 16s ease-in-out infinite;

  .body {
    position: relative;
    height: 100%;
    width: 100%;
    border-radius: 66px;
    border: 15px solid #f8a296;
    animation: float 16s ease-in-out infinite;
  }
}

.particle4 {
  position: absolute;
  z-index: 0;
  width: 80px;
  height: 80px;
  bottom: 0px;
  right: 200px;
  animation: particle4 16s ease-in-out infinite;

  .body {
    position: relative;
    height: 100%;
    width: 100%;
    border-radius: 24px;
    border: 22px solid #f8f8f8;
    animation: spin 16s ease-in-out infinite reverse;
  }
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(0px);
  }
}

@keyframes phone1 {
  0% {
    transform: translate(0px, 0px);
  }
  5% {
    transform: translate(132px, -20px);
  }
  30% {
    transform: translate(132px, -20px);
  }
  40% {
    transform: translate(132px, 58px);
  }
  60% {
    transform: translate(132px, 58px);
  }
  70% {
    transform: translate(132px, -20px);
  }
  85% {
    transform: translate(132px, -20px);
  }
  90% {
    transform: translate(0px, 0px);
  }
}

@keyframes phone2 {
  0% {
    transform: translate(0px, 0px);
  }
  5% {
    transform: translate(-132px, 58px);
  }
  30% {
    transform: translate(-132px, 58px);
  }
  40% {
    transform: translate(-132px, -20px);
  }
  60% {
    transform: translate(-132px, -20px);
  }
  70% {
    transform: translate(-132px, 58px);
  }
  85% {
    transform: translate(-132px, 58px);
  }
  90% {
    transform: translate(0px, 0px);
  }
}

@keyframes phone3 {
  0% {
    transform: translate(0px, 0px);
  }
  5% {
    transform: translate(-132px, 58px);
  }
  30% {
    transform: translate(-132px, 58px);
  }
  40% {
    transform: translate(-132px, -20px);
  }
  60% {
    transform: translate(-132px, -20px);
  }
  70% {
    transform: translate(-132px, 58px);
  }
  85% {
    transform: translate(-132px, 58px);
  }
  90% {
    transform: translate(0px, 0px);
  }
}

@keyframes tablet {
  0% {
    transform: scale(0) (0px, 0px);
    opacity: 0;
  }
  2% {
    transform: scale(0) (0px, 0px);
    opacity: 0;
  }
  5% {
    transform: scale(1) translate(132px, -20px);
    opacity: 1;
  }
  30% {
    transform: scale(1) translate(132px, -20px);
    opacity: 1;
  }
  40% {
    transform: scale(1) translate(132px, 58px);
    opacity: 1;
  }
  60% {
    transform: scale(1) translate(132px, 58px);
    opacity: 1;
  }
  70% {
    transform: scale(1) translate(132px, -20px);
    opacity: 1;
  }
  85% {
    transform: scale(1) translate(132px, -20px);
    opacity: 1;
  }
  90% {
    transform: scale(0) translate(0px, 0px);
    opacity: 1;
  }
}

@keyframes particle1 {
  0% {
    transform: translate(0px, 0px);
  }
  5% {
    transform: scale(1) translate(-222px, 58px);
  }
  30% {
    transform: scale(1) translate(-222px, 58px);
  }
  40% {
    transform: scale(1.25) translate(-182px, -20px);
  }
  60% {
    transform: scale(1.25) translate(-182px, -20px);
  }
  70% {
    transform: scale(1) translate(-222px, 58px);
  }
  85% {
    transform: scale(1) translate(-222px, 58px);
  }
  90% {
    transform: translate(0px, 0px);
  }
}

@keyframes particle2 {
  0% {
    transform: scale(0) translate(0px, 0px);
  }
  5% {
    transform: scale(0.5) translate(0px, 300px);
  }
  30% {
    transform: scale(0.5) translate(0px, 300px);
  }
  40% {
    transform: scale(0.4) translate(0px, 100px);
  }
  60% {
    transform: scale(0.4) translate(0px, 100px);
  }
  70% {
    transform: scale(0.5) translate(0px, 200px);
  }
  85% {
    transform: scale(0.5) translate(0px, 200px);
  }
  90% {
    transform: scale(0) translate(0px, 0px);
  }
}

@keyframes particle3 {
  0% {
    transform: scale(0) translate(0px, 0px);
  }
  5% {
    transform: scale(1.5) translate(125px, 0px);
  }
  30% {
    transform: scale(1.5) translate(125px, 0px);
  }
  40% {
    transform: scale(1.25) translate(125px, 0px);
  }
  60% {
    transform: scale(1.25) translate(125px, 0px);
  }
  70% {
    transform: scale(1.5) translate(125px, 0px);
  }
  85% {
    transform: scale(1.5) translate(125px, 0px);
  }
  90% {
    transform: scale(0) translate(0px, 0px);
  }
}

@keyframes particle4 {
  0% {
    transform: scale(0) translate(0px, 0px);
  }
  5% {
    transform: scale(1.5) translate(125px, 0px);
  }
  30% {
    transform: scale(1.5) translate(125px, 0px);
  }
  40% {
    transform: scale(1.75) translate(125px, 75px);
  }
  60% {
    transform: scale(1.75) translate(125px, 75px);
  }
  70% {
    transform: scale(1.5) translate(125px, 0px);
  }
  85% {
    transform: scale(1.5) translate(125px, 0px);
  }
  90% {
    transform: scale(0) translate(0px, 0px);
  }
}
