.container {
  position: absolute;
  inset: 0;
  left: 394px;
}

.club {
  position: absolute;
  left: 1.2%;
  top: 13%;
  min-width: 192px;

  > div:first-child > div:last-child {
    color: #f8a296;
  }
}

.tourism {
  position: absolute;
  left: 13.5%;
  top: 41%;
  min-width: 192px;
  > div:first-child > div:last-child {
    color: #0795ed;
  }
}

.couple {
  position: absolute;
  left: 26.4%;
  top: -3.5%;
  min-width: 192px;
}

.food {
  position: absolute;
  left: 41%;
  top: 43%;
  min-width: 192px;
  > div:first-child > div:last-child {
    color: #fdcb02;
  }
}

.sports {
  position: absolute;
  right: 27.6%;
  top: 17.6%;
  min-width: 192px;
  > div:first-child > div:last-child {
    color: #7d68eb;
  }
}

.comedy {
  position: absolute;
  right: 6.4%;
  top: 14.7%;
  min-width: 192px;

  > div:first-child > div:last-child {
    color: #fe8d5d;
  }
}

.family {
  position: absolute;
  right: 7.8%;
  top: 51.3%;
  min-width: 192px;

  > div:first-child > div:last-child {
    color: #00b268;
  }
}

.food,
.sports,
.comedy,
.family {
  display: none;
  position: absolute;
  min-width: 192px;
}

@media screen and (min-width: 674px) {
  .container {
    left: 0;
  }

  .club {
    left: 13.3%;
    top: 6%;
  }

  .couple {
    left: 35%;
    top: 9%;
  }

  .food,
  .sports,
  .comedy,
  .family {
    display: flex;
  }
}

@media screen and (min-width: 1440px) {
  .club {
    left: 15%;
    top: 8%;
  }

  .tourism {
    left: 12.7%;
    top: 46.1%;
  }

  .couple {
    left: 36.5%;
    top: 12%;
  }

  .food {
    left: 42.3%;
    top: 42%;
  }

  .sports {
    right: 33.6%;
    top: 19.5%;
  }

  .comedy {
    right: 8.9%;
    top: 16.1%;
  }

  .family {
    right: 10.2%;
    top: 49%;
  }
}

@media screen and (min-width: 2560px) {
  .club {
    left: 15.6%;
    top: 12%;
    min-width: 304px;
  }

  .tourism {
    left: 13.3%;
    top: 50.1%;
    min-width: 304px;
  }

  .couple {
    left: 37.1%;
    top: 15.2%;
    min-width: 304px;
  }

  .food {
    left: 43%;
    top: 45%;
    min-width: 304px;
  }

  .sports {
    right: 34.2%;
    top: 23%;
    min-width: 304px;
  }

  .comedy {
    right: 9.4%;
    top: 19.1%;
    min-width: 304px;
  }

  .family {
    right: 10.8%;
    top: 52.8%;
    min-width: 304px;
  }
}
