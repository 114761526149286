.button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
  border-radius: 32px;
  border: none;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.2);
  transition: background-color 0.2s;
  column-gap: 8px;
  border: 1px solid #fff;

  .spinner {
    width: 34px;
    height: 34px;
    margin: 11px;
    border: 4px solid #f3f1f1;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }

  &.dark {
    background: transparent;
    color: #fff;
    &:active {
      background: #ffffffcf;
    }
  }

  &.light {
    background: #fff;
    color: #8c5eff;
    &:active {
      background: #ffffffcf;
    }
  }

  &.filled {
    color: #fff;
    background: #7644f4;
    border: none;
  }

  span {
    transition: color 0.2s;
    font-family: integralcf-bold;
    font-size: 16px;
    font-weight: 800;
    line-height: 24px;
    padding: 16px 28px;
    text-transform: uppercase;
  }

  &.disabled {
    cursor: default;
    background: #b699ff !important;
    color: #fff;
    pointer-events: none;
    border: none;
  }

  &.business {
    &.light {
      background: #fff;
      color: #00b6f4;
      &:active {
        background: #ffffffcf;
      }
    }

    &.filled {
      color: #fff;
      background: #00b6f4;
      border: none;
    }
  }
}

@media (hover: hover) {
  .button {
    &:hover {
      background: #fff;
      color: #292933;
    }

    &.dark {
      &:hover {
        color: #292933;
      }
    }

    &.light {
      &:hover {
        color: #fff;
        background: #7644f4 !important;
      }
    }

    &.filled {
      &:hover {
        color: #fff;
        background: #7644f4 !important;
      }
    }

    &.disabled {
      &:hover {
        cursor: not-allowed;
        background: #b699ff !important;
        color: #fff;
      }
    }
    &.business {
      &.light {
        &:hover {
          color: #fff;
          background: #0074b7 !important;
        }
      }

      &.filled {
        &:hover {
          color: #fff;
          background: #0074b7 !important;
        }
      }
    }
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
