.icon {
  position: relative;
  display: flex;
  z-index: 2;
  background-color: #fef0c7;
  border-radius: 50%;
  height: 48px;
  width: 48px;
  align-items: center;
  justify-content: center;

  i {
    color: #dc6803;
    font-size: 30px;
    position: relative;
    left: -2px;
  }
}

.icon::before {
  content: "";
  display: block;
  position: absolute;
  inset: 0;
  animation: pulse 1s ease infinite;
  border-radius: 50%;
  border: 4px double lighten(#dc6803, 20%);
}

.container {
  display: flex;
  align-items: center;
}

.body {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  background-color: #f3f1f1;
  width: 100%;
  height: 100%;
  min-height: 200px;
  min-width: 300px;
  row-gap: 16px;
}

.header {
  display: flex;
  column-gap: 16px;
  align-items: center;

  span {
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    text-align: left;
    color: #101828;
  }
}

.subHeader {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #475467;
}

.actionBtns {
  display: flex;
  column-gap: 12px;
}

.button {
  span {
    font-size: 12px;
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  60% {
    transform: scale(1.3);
    opacity: 0.4;
  }
  100% {
    transform: scale(1.4);
    opacity: 0;
  }
}
