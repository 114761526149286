.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  overflow: hidden;
  padding-top: 50px;
}

.footerContainer {
  display: flex;
  mix-blend-mode: luminosity;
  position: relative;
  left: 290px;
  &::before {
    content: "";
    position: absolute;
    z-index: 0;
    background: #7339ff;
    height: 100%;
    width: 93%;
    left: -45%;
    top: 27%;
    transform: rotate(-27deg);
    border-radius: 100%;
  }

  &::after {
    content: "";
    position: absolute;
    z-index: 0;
    background: #7339ff;
    height: 100%;
    width: 93%;
    right: -45%;
    top: 27%;
    transform: rotate(27deg);
    border-radius: 100%;
  }
}

.map {
  position: relative;
  z-index: 1;
  object-fit: cover;
  width: 100%;
  max-width: 2560px;
  transform: scale(1.3);
  transform-origin: top;
  min-width: 1200px;
  min-height: 472px;
}

.bubbleContainer {
  display: flex;
  max-width: 2560px;
  min-width: 1200px;
  width: 100%;
  min-height: 472px;
  position: absolute;
}

@media screen and (min-width: 674px) {
  .container {
    padding-top: 0px;
  }

  .footerContainer,
  .bubbleContainer {
    height: 100%;
    left: unset;
  }

  .map,
  .bubbleContainer {
    min-width: 1200px;
    max-width: 1200px;
  }
}

@media screen and (min-width: 1440px) {
  .map,
  .bubbleContainer {
    min-width: 1440px;
    max-width: 1440px;
  }
}

@media screen and (min-width: 2560px) {
  .map,
  .bubbleContainer {
    min-width: 2560px;
    max-width: 2560px;
  }
}
