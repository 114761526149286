.v2-img-container {
  height: 100%;
  width: 100%;
  position: relative;
}

.v2-image {
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  max-height: 14rem;
  transition: .5s ease;
  backface-visibility: hidden;
  margin-bottom: 1rem;
  border-radius: var(--bs-border-radius-xl);
  /* height: 14rem; */
  object-fit: cover;
  background-color: var(--bs-white);

}

.v2-image-circle {
  opacity: 1;
  /* width: 80px; */
  /* height: 80px; */
  max-height: 100px;
  transition: .5s ease;
  backface-visibility: hidden;
  border-radius: 80px;
}

.v2-image-circle-md {
  opacity: 1;
  width: 200px;
  height: 200px;
  max-height: 200px;
  transition: .5s ease;
  backface-visibility: hidden;
  border-radius: 200px;
}

.v2-middle {
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}



/* .v2-middle1 {
  opacity: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
} */

/* .v2-be {
  opacity: 1;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: right;
} */

.v2-img-container:hover .v2-image {
  opacity: 0.9;
}

.v2-img-container:hover .v2-middle {
  opacity: 1;
}


.v2-thumb {
  height: 300px;
  width: 300px;
}